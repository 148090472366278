import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import getUtils from 'wapplr-react/dist/common/Wapp/getUtils'
import { WappContext } from 'wapplr-react/dist/common/Wapp'

import clsx from 'clsx'

import GithubIcon from 'digby-ui/dist/common/src/svg/GithubIcon'

import { copyObject } from 'wapplr/dist/common/utils'

import Home from '../Pages/Home'
import Container from '../Pages/Container'
import Color from '../Pages/Color'
import Typography from '../Pages/Typography'
import SvgIcon from '../Pages/SvgIcon'
import Logo from '../Pages/Logo'
import Paper from '../Pages/Paper'
import Button from '../Pages/Button'
import Label from '../Pages/Label'
import MenuItem from '../Pages/MenuItem'
import Input from '../Pages/Input'
import Modal from '../Pages/Modal'
import Menu from '../Pages/Menu'
import AppBar from '../Pages/AppBar'
import Image from '../Pages/Image'
import Avatar from '../Pages/Avatar'

import Template from '../Template'
import Dialog from '../Dialog'

import AppContext from './context'

import { storage as localStorage } from './localStorage'
import { storage as memoStorage } from './memoStorage'

import defaultStyle from './component.css'

import UAParser from 'ua-parser-js'

function OfflineLayer(props) {

    const [show, setShow] = useState(props.show || false)

    useEffect(() => {
        if (props.effect) {
            props.effect({
                actions: {
                    setShow
                }
            })
        }
        return () => {
            if (props.effect) {
                props.effect({
                    actions: {
                        setShow: async () => null
                    }
                })
            }
        }
    })

    return (
        <div className={clsx(
            props.classNames.offlineLayer,
            { [props.classNames.offlineLayerShow]: show }
        )} />
    )
}

function NewReleasePopup(props) {

    const context = useContext(WappContext)
    const appContext = useContext(AppContext)

    const { wapp } = context
    const { effect } = props

    const dialog = useRef()

    useEffect(() => {
        if (effect) {
            effect({
                actions: {
                    open: () => {
                        dialog.actions?.open({
                            dialogTitle: appContext.titles.newReleaseWarningTitle,
                            dialogContent: appContext.messages.newReleaseWarningContent,
                            cancelText: appContext.labels.newReleaseCancelText,
                            submitText: appContext.labels.newReleaseSubmitText,
                            onSubmit: async () => {
                                await wapp.pwaUnregister()
                                window.location.reload()
                            }
                        })
                    }
                }
            })
        }
    })

    return (
        <Dialog
            effect={({ actions }) => {
                dialog.actions = actions
            }}
            ContentComponentProps={{
                Component: 'div'
            }}
        />
    )
}

export default function App(p = {}) {

    const context = useContext(WappContext)
    const { wapp, req, res } = context
    const utils = getUtils(context)

    const props = {
        ...p,
        ...res.wappResponse.content?.ComponentProps ? res.wappResponse.content.ComponentProps : {}
    }

    const {
        subscribe,
        parentRoute = '',
        fullPage,
        classNames = defaultStyle,
        TemplateProps = {}
    } = props

    //wapp.styles.use(classNames)

    const [url, setUrl] = useState(utils.getRequestUrl())

    const template = useRef()
    const waitForRender = useRef(true)
    const buttonLastHrefPushToHistory = useRef({})
    const networkStateOffline = useRef(false)
    const offlineLayer = useRef()
    const newRelease = useRef()

    useEffect(() => {
        if (wapp.target === 'web' && wapp.client) {
            wapp.client.onlineHandler = () => {
                template.current?.actions?.setSnackMessage(appContext.messages.onlineAgain, 3000)
                template.current?.actions?.setLogoBlackAndWhite(false)
                networkStateOffline.current = false
                offlineLayer.actions.setShow(false)
                document.body.style.position = null
                document.body.style.overflow = null
                document.body.style.width = null
                document.body.style.height = null
                setTimeout(async () => {
                    await wapp.pwaUnregister()
                    window.location.reload()
                })
            }
            wapp.client.offlineHandler = () => {
                template.current?.actions?.setSnackMessage(appContext.messages.offlineWarning, 6000, 'error')
                template.current?.actions?.setLogoBlackAndWhite(true)
                networkStateOffline.current = true
                offlineLayer.actions.setShow(true)
                document.body.style.position = 'fixed'
                document.body.style.overflow = 'hidden'
                document.body.style.width = '100vw'
                document.body.style.height = '100vh'
            }
            wapp.client.serviceWorkerUpdated = () => {
                if (!networkStateOffline.current) {
                    newRelease.actions?.open()
                }
            }
            wapp.client.handleNotFoundResponse = () => {
                if (!networkStateOffline.current) {
                    newRelease.actions?.open()
                }
            }
        }
    }, [])

    const storageName = wapp.globals.NAME
    const storage = function(data, memo) {
        if (memo) {
            return memoStorage((data) ? data : {}, storageName)
        }
        return localStorage(data, storageName)
    }

    function saveScrollTopAfterFinishedRender() {
        const urlKey = wapp.client.history.getState().key || 'initial'
        let scrollTop = storage(undefined, true)['scrollTop_' + urlKey] || 0
        storage({ ['shouldScrollTopAfterRender']: scrollTop }, true)
    }

    async function setScrollTop() {

        let scrollTop = storage(undefined, true)['shouldScrollTopAfterRender']
        let smooth = false

        if (scrollTop >= 0) {
            storage({ ['shouldScrollTopAfterRender']: -1 }, true)
            try {
                if (scrollTop === 0 && window.location.hash) {
                    const e = document.getElementById(window.location.hash.slice(1))
                    if (e) {

                        const maxScrollY = template.current?.actions?.getMaxScrollY() || 0
                        scrollTop = e.getBoundingClientRect().top + window.scrollY - 100 - 16

                        if (maxScrollY < scrollTop && maxScrollY) {
                            scrollTop = maxScrollY
                        }

                        smooth = true
                    }
                }
            } catch (e) {
            }

            if (template.current?.actions?.setScrollTop) {
                await template.current.actions.setScrollTop(scrollTop, smooth)
            }

        }
    }

    function createStates() {
        if (!wapp.states.stateManager.actions.app) {
            wapp.states.stateManager.actions.app = function({ type, name, value }) {
                return {
                    type: type || 'SET_APP',
                    payload: {
                        name,
                        value
                    }
                }
            }
            wapp.states.stateManager.reducers.app = function(state = {}, action) {
                switch (action.type) {
                    case 'SET_APP':
                        return {
                            ...state,
                            [action.payload.name]: (action.payload.value && typeof action.payload.value == 'object') ? copyObject(action.payload.value) : action.payload.value
                        }
                    default:
                        return state
                }
            }

            if (wapp.target === 'web') {
                wapp.client.history.globalHistory.scrollRestoration = 'manual'
            }

        }
    }

    function storeUrl(action, url, { key = 'initial' }) {

        const history = res.wappResponse.store.getState('app.history') || []

        const lastItem = history[history.length - 1]

        if (lastItem && lastItem.key !== key || !lastItem) {

            const current = {
                action: action || 'PUSH',
                url: url,
                key,
                referrer: lastItem?.url || ''
            }

            history.push(current)

            res.wappResponse.store.dispatch(wapp.states.runAction('app', { name: 'history', value: history }))
            res.wappResponse.store.dispatch(wapp.states.runAction('app', { name: 'current', value: current }))

        }

    }

    function subscribeAppStore() {

        createStates()

        const unsubscribeFromState = res.wappResponse.store.subscribe(function({ type, payload }) {
            if (wapp.target === 'web' && wapp.globals.DEV) {
                console.log('[APP] Change state:', type, payload)
            }
        })

        const unsubscribeHistoryListener = (wapp.target === 'web') ?
            wapp.client.history.addListener(function({ action, location, state }) {
                storeUrl(action, location.pathname + location.search + location.hash, state)
                if (action === 'POP') {
                    delete buttonLastHrefPushToHistory.current.time
                    delete buttonLastHrefPushToHistory.current.href
                }
            }) : null

        return () => {
            unsubscribeFromState()
            unsubscribeHistoryListener()
        }

    }

    /*todo: here, all user interactions, such as scroll and click, will have to be saved in the app state*/

    if (wapp.target === 'node') {
        createStates()
        storeUrl('PUSH', url, { key: 'initial' })
    }

    useEffect(() => {
        const unsubscribe = subscribeAppStore()
        const key = wapp.client.history.getState().key || 'initial'
        storeUrl('PUSH', url, { key })
        return unsubscribe
    }, [])

    if (wapp.target === 'web') {
        useLayoutEffect(() => {
            if (waitForRender.current) {
                waitForRender.current = false
                setScrollTop()
            }
        }, [url])
    }

    async function onLocationChange(newUrl) {
        if (template.current?.actions?.setLogoAnimation) {
            // noinspection ES6MissingAwait
            template.current.actions.setLogoAnimation(1)
        }
        saveScrollTopAfterFinishedRender()
        if (url !== newUrl) {
            waitForRender.current = true
            setUrl(newUrl)
        } else {
            await setScrollTop()
            if (template.current?.actions?.setLogoAnimation) {
                // noinspection ES6MissingAwait
                template.current.actions.setLogoAnimation(0)
            }
        }
    }

    useEffect(function() {
        const unsub1 = subscribe.locationChange(onLocationChange)
        return function useUnsubscribe() {
            unsub1()
        }
    }, [subscribe, url])

    useEffect(() => {
        if (template.current?.actions?.setLogoAnimation) {
            const history = res.wappResponse.store.getState('app.history') || []
            const lastItem = history[history.length - 1]
            if (lastItem.key !== 'initial' && history.length > 1) {
                template.current.actions.setLogoAnimation(0)
            }
        }
    })

    useEffect(() => {
        if (wapp.target === 'web') {
            const cssAssets = res.wappResponse.store.getState('res.assets.css')
            const themeCss = cssAssets.find((css) => css.match('theme'))

            if (themeCss) {

                const criticalCss = cssAssets.find((css) => css.match('critical'))

                function getReference() {
                    const globals = wapp.globals
                    const { WAPP } = globals
                    const criticalStyle = document.getElementById('css_' + WAPP)
                    const criticalLink = (criticalCss) ? document.getElementById(criticalCss) : null
                    return criticalLink || criticalStyle
                }

                async function success() {
                    console.log('[APP] Full theme loaded...')
                    await new Promise((resolve) => setTimeout(resolve, 1500))
                    const reference = getReference()
                    if (reference) {
                        const head = document.getElementsByTagName('head')[0]
                        head.removeChild(reference)
                    }
                }

                async function createLink() {

                    const head = document.getElementsByTagName('head')[0]
                    const link = document.createElement('link')
                    link.rel = 'stylesheet'
                    link.type = 'text/css'
                    link.href = themeCss
                    link.id = themeCss
                    link.onload = async () => {
                        success()
                    }

                    const reference = getReference()

                    head.insertBefore(link, reference ? reference.nextSibling : null)

                }

                console.log('[APP] Hydrated')

                if (!document.getElementById(themeCss)) {
                    function add() {
                        if (!document.getElementById(themeCss)) {
                            if (window.requestIdleCallback) {
                                window.requestIdleCallback(createLink)
                            } else {
                                createLink()
                            }
                        }
                    }

                    function on(e) {
                        if (e.isTrusted) {
                            add()
                            removeListeners()
                        }
                    }

                    function removeListeners() {
                        window.removeEventListener('touchstart', on)
                        window.removeEventListener('mousemove', on)
                    }

                    window.addEventListener('touchstart', on)
                    window.addEventListener('mousemove', on)

                    return () => {
                        removeListeners()
                    }

                }

            }
        }
    }, [])

    const { device } = new UAParser(req.wappRequest.userAgent).getResult()

    const appContext = {
        labels: {
            logoAreaLabel: 'Home',
            mobileMenu: 'Menu',
            themeControlsMode: 'Color scheme',
            themeControlsDarkMode: 'Dark',
            themeControlsLightMode: 'Light',
            themeControlsAutoMode: 'Default',
            themeControlsAutoModeSec: 'Device settings',
            themeControlsContrast: 'Contrast',
            themeControlsHighContrast: 'More contrast',
            newReleaseCancelText: 'Continue',
            newReleaseSubmitText: 'Reload'
        },
        menus: {
            themeControlsMenu: 'Theme colors'
        },
        messages: {
            preventClickAgain: 'The page is loading...',
            preventClickDone: 'This page is already loaded',
            offlineWarning: 'No Internet connection',
            onlineAgain: 'Internet connection restored',
            newReleaseWarningContent: 'The DIGBY-UI application has been updated, the page needs to be reloaded for further normal operation'
        },
        titles: {
            newReleaseWarningTitle: 'The application has been updated'
        },
        template,
        device
    }

    const route = res.wappResponse.route
    const requestPath = route.requestPath

    return (
        <AppContext.Provider value={appContext}>
            <Template
                {...TemplateProps}
                menu={[
                    { label: 'Getting started', href: '/' },
                    {
                        label: 'Components',
                        items: [
                            {
                                label: 'Base components',
                                items: [
                                    { label: 'Container', href: '/Container' },
                                    { label: 'Color', href: '/Color' },
                                    { label: 'SvgIcon', href: '/SvgIcon' },
                                    { label: 'Image', href: '/Image' },
                                    { label: 'Typography', href: '/Typography' },
                                    { label: 'Paper', href: '/Paper' },
                                    { label: 'Modal', href: '/Modal' }
                                ]
                            },
                            {
                                label: 'Complex components',
                                items: [
                                    { label: 'Logo', href: '/Logo' },
                                    { label: 'Button', href: '/Button' },
                                    { label: 'Label', href: '/Label' },
                                    { label: 'MenuItem', href: '/MenuItem' },
                                    { label: 'Input', href: '/Input' },
                                    { label: 'Menu', href: '/Menu' },
                                    { label: 'AppBar', href: '/AppBar' },
                                    { label: 'Avatar', href: '/Avatar' }
                                ]
                            }
                        ]
                    }
                ]}
                featuredButtons={[]}
                handlers={{
                    onScroll: (e) => {
                        if (!waitForRender.current) {
                            if (url === res.wappResponse.store.getState('app.current.url')) {
                                const urlKey = wapp.client.history.getState().key || 'initial'
                                const scrollTop = e.target === document ? window.scrollY : e.target.scrollTop
                                storage({ ['scrollTop_' + urlKey]: scrollTop }, true)
                            }
                        }
                    }
                }}
                effect={({ actions }) => {
                    template.current = {
                        actions
                    }
                }}
                ButtonContextValue={{
                    href: ({ href }) => {

                        if (!href) {
                            return href
                        }

                        let internal = false
                        try {
                            const url = new URL(href, window.location.origin)
                            internal = (url && url.origin === window.location.origin && url.href.startsWith(window.location.origin))
                        } catch (e) {

                        }

                        if (internal) {
                            return href ? parentRoute + href : href
                        }

                        return href
                    },
                    onClick: async function(e, { href, target, disabled }) {

                        if (disabled) {
                            e.preventDefault()
                            e.stopPropagation()
                            return
                        }

                        if (!href || target === '_blank') {

                        } else {

                            if (href && href.startsWith('https://github.com/digby/digby-ui/tree/master/src/common/components')) {
                                e.preventDefault()
                                href = parentRoute + href.split('https://github.com/digby/digby-ui/tree/master/src/common/components')[1]
                            }

                            try {
                                const hrefUrl = new URL(href, window.location.origin)
                                if (hrefUrl && hrefUrl.origin === window.location.origin && hrefUrl.href.startsWith(window.location.origin)) {

                                    e.preventDefault()
                                    e.stopPropagation()

                                    if (networkStateOffline.current) {
                                        appContext.template.current?.actions?.setSnackMessage(appContext.messages.offlineWarning, 6000, 'error')
                                        return
                                    }

                                    let isSamePage = false
                                    try {
                                        const absoluteUrl = new URL(url, window.location.origin)
                                        if (absoluteUrl.href === hrefUrl.href) {
                                            isSamePage = true
                                        }
                                    } catch (e) {

                                    }

                                    const wait = 1000 * (isSamePage ? 5 : 10)
                                    const now = Date.now()

                                    if (buttonLastHrefPushToHistory.current.href === hrefUrl.href && buttonLastHrefPushToHistory.current.time + wait > now) {
                                        console.log('[APP] Prevent click again... wait ' + (buttonLastHrefPushToHistory.current.time + wait - now) + ' ms')
                                        template.current.actions.setLogoAnimation(0)
                                        appContext.template.current?.actions?.setSnackMessage((isSamePage) ? appContext.messages.preventClickDone : appContext.messages.preventClickAgain)
                                        return
                                    }

                                    buttonLastHrefPushToHistory.current.time = Date.now()
                                    buttonLastHrefPushToHistory.current.href = hrefUrl.href

                                    if (template.current?.actions?.setLogoAnimation) {
                                        // noinspection ES6MissingAwait
                                        template.current.actions.setLogoAnimation(1)
                                    }

                                    wapp.client.history.push({
                                        search: '',
                                        hash: '',
                                        ...wapp.client.history.parsePath(hrefUrl.href.split(window.location.origin)[1])
                                    })

                                    //return
                                }
                            } catch (e) {
                            }

                        }
                    }
                }}
                MenuContextValue={{
                    isActive: (props) => {
                        if (typeof props.active == 'boolean') {
                            return props.active
                        }
                        const href = props.href ? parentRoute + props.href : props.href
                        return (href && href === url)
                    }
                }}
                MenuProps={{
                    storageOpenMenu: storage,
                    menuKey: 'digbyMainMenu'
                }}
                MobileMenuProps={{
                    storageDrawerScrollTop: storage
                }}
                ThemeControlsProps={{
                    storageOpenMenu: storage
                }}
                FooterProps={{
                    footerSubtitle: 'A React based UI kit for DIGBY Design',
                    footerChildren: [
                        {
                            menu: [
                                { label: 'Container', href: '/Container' },
                                { label: 'Color', href: '/Color' },
                                { label: 'SvgIcon', href: '/SvgIcon' },
                                { label: 'Image', href: '/Image' },
                                { label: 'Typography', href: '/Typography' },
                                { label: 'Paper', href: '/Paper' },
                                { label: 'Modal', href: '/Modal' }
                            ]
                        },
                        {
                            menu: [
                                { label: 'Logo', href: '/Logo' },
                                { label: 'Button', href: '/Button' },
                                { label: 'Label', href: '/Label' },
                                { label: 'MenuItem', href: '/MenuItem' },
                                { label: 'Input', href: '/Input' },
                                { label: 'Menu', href: '/Menu' },
                                { label: 'AppBar', href: '/AppBar' },
                                { label: 'Avatar', href: '/Avatar' }
                            ]
                        }
                    ],
                    footerSocialMenu: [
                        {
                            startIcon: <GithubIcon />,
                            label: 'Github',
                            style: {
                                '--digby-icon-font-size': '1.5em'
                            },
                            href: 'https://github.com/digby/digby-ui',
                            target: '_blank'
                        }
                    ],
                    footerCopyright: 'DIGBY-UI ' + new Date().getFullYear() + '. ©',
                    footerPaymentsImage: null
                }}
                LogoProps={{ ui: true }}
                fullPage={fullPage}
            >
                {(requestPath === parentRoute + '/') ?
                    <Home />
                    :
                    null
                }
                {(requestPath === parentRoute + '/Container') ?
                    <Container />
                    : null
                }
                {(requestPath === parentRoute + '/Color') ?
                    <Color />
                    : null
                }
                {(requestPath === parentRoute + '/SvgIcon') ?
                    <SvgIcon />
                    : null
                }
                {(requestPath === parentRoute + '/Logo') ?
                    <Logo />
                    : null
                }
                {(requestPath === parentRoute + '/Image') ?
                    <Image />
                    : null
                }
                {(requestPath === parentRoute + '/Typography') ?
                    <Typography />
                    : null
                }
                {(requestPath === parentRoute + '/Paper') ?
                    <Paper />
                    : null
                }
                {(requestPath === parentRoute + '/Button') ?
                    <Button />
                    : null
                }
                {(requestPath === parentRoute + '/Label') ?
                    <Label />
                    : null
                }
                {(requestPath === parentRoute + '/MenuItem') ?
                    <MenuItem />
                    : null
                }
                {(requestPath === parentRoute + '/Input') ?
                    <Input />
                    : null
                }
                {(requestPath === parentRoute + '/Modal') ?
                    <Modal />
                    : null
                }
                {(requestPath === parentRoute + '/Menu') ?
                    <Menu />
                    : null
                }
                {(requestPath === parentRoute + '/AppBar') ?
                    <AppBar />
                    : null
                }
                {(requestPath === parentRoute + '/Avatar') ?
                    <Avatar />
                    : null
                }
                <NewReleasePopup
                    effect={({ actions }) => {
                        newRelease.actions = actions
                    }}
                />
            </Template>
            <OfflineLayer
                effect={({ actions }) => {
                    offlineLayer.actions = actions
                }}
                classNames={classNames}
                show={networkStateOffline.current}
            />
        </AppContext.Provider>
    )
}
